import {ECOM_MEMBERS_AREA_DEFAULT_PAGES, ecomAppDefID} from '../constants';
import {addApplications, installMembersArea, registerMembersAreaApps} from '@wix/members-area-integration-kit';
import {PageMap} from '@wix/wixstores-client-core/dist/es/src/constants';
import {EcomPlatformInstallationState} from '../services/EcomPlatformInstallationState';
import {EcomComponent} from '@wix/ecom-platform-sdk';

export async function addPage(sdk: IEditorSdk, pageId: string, managingAppDefId: string, shouldNavigate: boolean) {
  managingAppDefId = pageId === PageMap.CHECKOUT ? ecomAppDefID : managingAppDefId;
  const pageData = await sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    managingAppDefId,
    componentType: sdk.document.tpa.TPAComponentType.Page,
    page: {
      pageId,
      shouldNavigate,
    },
  });

  return sdk.document.pages.setState('', {
    state: {
      [pageId]: [{id: pageData.pageRef.id}],
    },
  });
}

export function addWidget(sdk: IEditorSdk, widgetId: string, addToAllPages: boolean): Promise<string> {
  return sdk.tpa.add.component('', {
    appDefinitionId: ecomAppDefID,
    componentType: sdk.tpa.TPAComponentType.Widget,
    widget: {
      widgetId,
      allPages: addToAllPages,
    },
  });
}

export async function addMembersArea(editorType: string, firstInstall: boolean, biData?: {origin?: string}) {
  await registerMembersAreaApps(ECOM_MEMBERS_AREA_DEFAULT_PAGES);
  if (!firstInstall || editorType === 'ADI') {
    return Promise.resolve();
  }

  const options = biData && {biData};
  await installMembersArea(options);
  await addApplications(ECOM_MEMBERS_AREA_DEFAULT_PAGES, options);
}

export function openEcomBillingPage(editorSDK: IEditorSdk) {
  return editorSDK.editor.openBillingPage('', {premiumIntent: 'PAID', referrer: 'stores_app_product'});
}

export function updateEcomPlatformInstallations(
  ecomPlatformInstallationState: EcomPlatformInstallationState,
  appDefId: string,
  installedComponents: EcomComponent[]
) {
  ecomPlatformInstallationState.addInstalledApp(appDefId, installedComponents);
}

export async function updateEcomPagesForPagesPanel(
  editorSDK: IEditorSdk,
  ecomPlatformInstallationState: EcomPlatformInstallationState
) {
  const appDefId =
    ecomPlatformInstallationState.getInstalledAppDefIds().length === 1
      ? ecomPlatformInstallationState.getInstalledAppDefIds()[0]
      : ecomAppDefID;

  const tpaApplicationId = (await editorSDK.tpa.app.getDataByAppDefId('', ecomAppDefID)).applicationId;
  const allSitePages = await editorSDK.pages.data.getAll();
  const ecomPages = allSitePages.filter((page) => page.tpaApplicationId === tpaApplicationId);

  return Promise.all(
    ecomPages.map(async (pageData) => {
      if (pageData.tpaPageId === PageMap.CART || pageData.tpaPageId === PageMap.THANKYOU) {
        const pageRef = {id: pageData.id, type: pageData.type};
        await editorSDK.pages.data.update('', {
          pageRef,
          data: {managingAppDefId: appDefId},
        });
        editorSDK.pages.setState('', {
          state: {
            [pageData.tpaPageId]: [{id: pageData.id}],
          },
        });
      }
    })
  );
}
