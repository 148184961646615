import {sliderWidthMigration} from '../migration-scripts/sliderMigration';
import {adiMissingPagesMigration} from '../migration-scripts/adiMissingPagesMigration';
import {reinstallPage} from '../migration-scripts/reinstallPage';
import {wishlistPageId} from '../constants';
import {freeProductsLegacyFlag} from '../migration-scripts/freeProductsLegacyFlag';
import {productWithSubscriptionSaved} from '../migration-scripts/productWithSubscriptionSaved';
import {EcomPlatformInstallationState} from '../services/EcomPlatformInstallationState';
import {DependantApps} from '../services/DependantApps';
import {Logger} from '@wix/bi-logger-ec-sf';
import {STORES_APP_DEF_ID} from '../../stores-editor-script/constants';
import {updateEcomPagesForPagesPanel} from './publicApi';

export async function handleAction(
  {type, payload},
  {
    sdk,
    storeId,
    dependantApps,
    ecomPlatformInstallationState,
    biLogger,
  }: {
    sdk: IEditorSdk;
    storeId: string;
    dependantApps: DependantApps;
    ecomPlatformInstallationState: EcomPlatformInstallationState;
    biLogger: Logger;
  }
) {
  // eslint-disable-next-line @typescript-eslint/tslint/config
  try {
    if (type === 'removeAppCompleted') {
      const removedApp = payload?.appDefinitionId;
      const ecomVerticalInstallation = ecomPlatformInstallationState.getInstalledApp(removedApp);
      if (ecomVerticalInstallation) {
        await handleEcomDependencyAppRemoved(sdk, ecomPlatformInstallationState, removedApp);
      }
    } else if (type === 'migrate') {
      switch (payload.type) {
        case 'sliderWidth':
          return await sliderWidthMigration(sdk);
        case 'adiMissingPages':
          return await adiMissingPagesMigration(sdk, storeId, biLogger);
        case 'reinstallWishlist':
          return await reinstallPage(
            sdk,
            wishlistPageId,
            dependantApps.installWishlistPageInMembersArea.bind(dependantApps)
          );
        case 'reinstallCheckout':
          return await reinstallPage(sdk, 'checkout', dependantApps.addCheckoutIfNeeded.bind(dependantApps));
        case 'tryInstallWishlistTPA':
          return await dependantApps.installWishlistTPA();
        case 'freeProductsLegacyFlag':
          return await freeProductsLegacyFlag(sdk, payload.value);
        case 'productWithSubscriptionSaved':
          return await productWithSubscriptionSaved();
        default:
          return await Promise.resolve();
      }
    } else {
      return await Promise.resolve();
    }
  } catch (e) {
    return await Promise.reject(e);
  }
}

async function handleEcomDependencyAppRemoved(
  sdk: IEditorSdk,
  ecomPlatformInstallationState: EcomPlatformInstallationState,
  removedApp: string
) {
  ecomPlatformInstallationState.removeInstalledApp(removedApp);
  const allAppsRemoved = !ecomPlatformInstallationState.hasInstalledApps();
  if (allAppsRemoved) {
    await sdk.application.uninstall('', {openConfirmation: false});
  } else if (ecomPlatformInstallationState.getInstalledAppDefIds().length === 1) {
    await updateEcomPagesForPagesPanel(sdk, ecomPlatformInstallationState);
  }

  if (!allAppsRemoved && removedApp === STORES_APP_DEF_ID) {
    // remove stores widgets from stage
  }
}
